<template>
  <div class="container">
    <div class="text">
      地址：浙江省杭州市下城区凤起路334号同方财富大厦<br>
      电话：0571-85860500<br>
      邮箱：BD@tongfangwang.com<br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 7vw;
  background: #241B10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: #FFFFFF;
  font-size: 1.2vw;
}
</style>
