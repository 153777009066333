export const data = [
  {
    city: "杭州",
    assets: [
      {
        name: "同方财富大厦",
        introduction: "同方财富大厦是一幢甲级纯商务写字楼，其建筑风格融欧式古典和前卫时尚于一身，典雅、精致的金色全玻璃幕墙和拉丝扣板、花岗岩饰面，凸显业主尊荣与个性的同时，更倡导环保，以极富创意的空中花园展现超越时代的设计理念。项目在杭城首推的“复式办公”理念是完全与国际办公同步的高尚办公模式，崇尚静谧办公与洽谈办公的分离，充分满足企业追求完美的办公空间的需求；并将“菜单式装修”理念引入办公空间，采用英国FLOTEX环保地材，业主可以按照自己的意愿选择适合企业风格的办公环境，得以充分体现企业特色并提升企业形象。",
        images: [
          {
            path: "hangzhou/caifu-1.jpg",
          },
          {
            path: "hangzhou/caifu-2.jpg",
          }
        ]
      },
      {
        name: "苏荷汇",
        introduction: "杭州·苏荷汇是同方联合控股集团与蓝绿双城强强联手打造的杭州市中心武林双铁TOD商务私域，总建筑面积48000平方米，楼高29层，规划建面约46-140㎡共11种户型。该项目打造约1.7万方漫生活街区、酒店、精品咖啡、餐饮、超市等等。苏荷汇位于武林正芯，一站比邻武林壹号、白马公寓；两站比邻绿城凤起潮鸣；一站比邻锦尚和品等塔尖豪宅。苏荷汇拥揽湖滨银泰、嘉里中心、杭州大厦、武林银泰、国大城市广场等杭州顶尖商业体。未来将建成恒隆广场、嘉里城、杭州中心万象城等，加之苏荷汇本身自带漫生活商业街区，未来繁华程度超乎所想。苏荷汇周边环伺着艮山门站全国首个高铁上盖项目、国际旅游文化综合体——嘉里城、浙商回归企业总部文创产业园、杭州商业储运公司项目等将陆续亮相，将共同助力武林区域整体升级、更新。苏荷汇地下二层直连地铁 1、5号线枢纽打铁关站，乘地铁可轻松转至杭州东站、杭州城站、杭州南站、萧山机场等交通枢纽；便捷畅达湖滨商圈、武林商圈、城市之星等鼎级商业区；同时，与地面交通链接亦可快速抵达未来科技城、滨江、钱江新城三大产业高地。",
        images: [
          {
            path: "hangzhou/suhehui-1.jpg",
          },
          {
            path: "hangzhou/suhehui-2.jpg",
          },
          {
            path: "hangzhou/suhehui-3.jpg",
          },
          {
            path: "hangzhou/suhehui-4.jpg",
          },
        ]
      },
      {
        name: "同方国际酒店式公寓",
        introduction: "同方国际酒店式公寓，坐落于有“小西湖”之称的华家池版块，毗邻浙大华家池校区，以百米标高的姿态屹立于秋涛北路与艮山西路交汇处，东瞰钱江新城，西俯浙大华家池，以华家池畔的绝版地段，成就目前杭州市中心罕见的高端酒店式公寓。",
        images: [
          {
            path: "hangzhou/guoji-1.jpg",
          },
          {
            path: "hangzhou/guoji-2.jpg",
          },
          {
            path: "hangzhou/guoji-3.jpg",
          },
          {
            path: "hangzhou/guoji-4.jpg",
          },
          {
            path: "hangzhou/guoji-5.jpg",
          },
        ]
      },
      {
        name: "超级星期天",
        introduction: "超级星期天的建筑体型呈梭形，总长145.4M，宽39.8M，高为48.5M，南侧16层，北侧14层。本工程为一类高层建筑，标准层为单身公寓，分为一室一厅，两室两厅，三室两厅等户型，地层功能为会所，顶部两层因立面斜切处理，局部房间作了跃层或阁楼。超级星期天拥有近2000平方米引以为傲的绿色中庭。形形色色的植物、景观小品，或组团，或统一，在宽敞的中庭争奇斗艳，美不胜收。从顶处洒落的阳光，在建筑与园林交融的空间欢快的跳跃，赋予建筑以灵动的生命。植物的绿意，阳光的七彩，使超级星期天成为色彩的画室，自然的原乡。近2000平米的大型会所，体现了开发商对业主的充分尊重:戏水池、健身房、壁球室、晨跑小径等运动设施，将年轻人的活力推向级致;网络咖啡厅、美容中心、棋牌室、读书走廊等休闲场所，让生活多一份逸趣，多一份底蕴。",
        images: [
          {
            path: "hangzhou/xingqitian-1.jpg",
          },
        ]
      },
      {
        name: "同方燕语林森",
        introduction: "同方燕语林森位于滨江区腹地千年古镇西兴镇，是同方联合控股集团旗下迪佛房产开发公司着力打造的精致品牌社区之一。社区东临固陵路，南临滨安路，1号线地铁出口离燕语林森项目约100米左右，出入方便。燕语林森以“追求楼盘本身的高品质”为首要开发纲领，并将其渗透延伸到楼盘的规划、景观、建筑、地段这三个重要环节上。全区由7幢多层建筑，6幢小高层住宅及部分沿街商铺优化组合而成，建筑密度25.16%，绿化率39.81%，户型以130平米左右的三房二厅为主。燕语林森同属滨江板块，同时拥有比较大啊的资源优势，大面积的绿化，高眺江景色已经是难能可贵，加之在规划上寓自然、古典与时尚于一体的设计，使人轻灵雅致、健康舒适。",
        images: [
          {
            path: "hangzhou/yanyu-1.jpg",
          },
        ]
      },
    ]
  },
  {
    city: "诸暨",
    assets: [
      {
        name: "同方豪生大酒店",
        introduction: "诸暨同方豪生大酒店位于诸暨市著名的西施故里风景区内，总占地面积30641.7平方米，占地46亩。总建筑面积57855.52平方米，其中地上40330.82平方米，地下建筑面积17524.7平方米，于2011年11月28日正式营业，是诸暨仅挂牌2家五星级酒店之一（豪生国际品牌酒店）。酒店拥有西餐厅、中餐厅、日料等特色餐厅、宴会厅10个，可同时容纳约3000人就餐。共拥有客房311间，其中大床房117间，双床房176间，套房12间，豪华套房6间，总层数为6层，其中地下建筑面积17524.7平方米中6000多平方米为休闲娱乐场所。",
        images: [
          {
            path: "zhuji/hangsheng-1.jpg",
          },
          {
            path: "zhuji/hangsheng-2.jpg",
          },
          {
            path: "zhuji/hangsheng-3.jpg",
          },
          {
            path: "zhuji/hangsheng-4.jpg",
          },
        ]
      },
      {
        name: "国际公馆",
        introduction: "同方国际公馆独占西施故里的核心要地，尽揽浦阳江畔的一线江景，百里挑一的绝版地块。与同方国际大饭店，诸暨首家由国际公司管理的五星级酒店，首度联合，强强联袂，成就左右诸暨豪宅方向的国际生活双核地标。",
        images: [
          {
            path: "zhuji/guoji-1.jpg",
          },
        ]
      },
      {
        name: "同方蓝铂",
        introduction: "同方蓝铂，做为诸暨唯一的酒店式办公的项目，成为成功的企业家首选的办公空间。既吸收了星级酒店较好的服务功能和管理模式，又吸收了住宅、写字楼的一些特点；既可居住，又可办公的综合性很强的物业，与普通住宅楼比较起来，其结构和设计均有所不同。与诸暨同方国际大酒店相毗邻，配套齐全、高档，能为住房提供星级酒店式的生活环境和物业管理。",
        images: [
          {
            path: "zhuji/lanbo-1.jpg",
          },
          {
            path: "zhuji/lanbo-2.jpg",
          },
          {
            path: "zhuji/lanbo-3.jpg",
          },
          {
            path: "zhuji/lanbo-4.jpg",
          },
        ]
      },
    ]
  },
  {
    city: "嘉兴",
    assets: [
      {
        name: "燕语林苑",
        introduction: "嘉兴燕语林苑，位于嘉兴市的楼盘，住宅区：位于东至东栅中学，南至南溪西路，西至李家桥港，北至泾水公寓，用地面积49163.5平方米，总建筑面积120076.7平方米。由2幢多层，12幢小高层，2幢高层组成。该项目与凌公塘生态公园为邻，小区绿色环境与外部生态环境融为一体，寓意人们生活在自然和谐与鸟语花香的醇美意境之中，故名。",
        images: [
          {
            path: "jiaxing/yanyu-1.jpg",
          },
          {
            path: "jiaxing/yanyu-2.jpg",
          },
          {
            path: "jiaxing/yanyu-3.jpg",
          },
          {
            path: "jiaxing/yanyu-4.jpg",
          },
          {
            path: "jiaxing/yanyu-5.jpg",
          },
          {
            path: "jiaxing/yanyu-6.jpg",
          },
          {
            path: "jiaxing/yanyu-7.jpg",
          },
        ]
      },
    ]
  },
  {
    city: "桐庐",
    assets: [
      {
        name: "同方春江燕语",
        introduction: "桐庐·同方春江燕语是同方集团着力打造又一高档住宅区。位于桐庐县美丽的富春江北岸，距桐庐县中心仅7.5千米，在交通便捷的320国道西南侧，总面积13万多平方。小区集别墅、Hasa亚墅等于一体，配套农庄、游艇，目标是打造精致的住宅生活区。小区内外山清水秀，景色秀丽，西为绿色山林茶园，北靠320国道30米景观带。东南两侧可以俯瞰山水长卷富春江，小区中心偏南是山谷汇集而成的山泉水塘，面向西南是一条绿色荫荫的林地。所有的一切都是大自然的馈赠，丝毫没有人工做作的痕迹。在如画的自然风光下，同方联合希望将融入其中的春将燕语着力打造成一片看风景的房子，而房子本身的精致同时也成为山水中一道亮丽的风景。基于这样的主题，小区的整体规划设计充分围绕自然与生态的完美结合，试图巧用自然的地形地貌，设计了幼儿园、会所、网球场等齐全的配套设施。小区有春江旭日、山林燕语、流水清音、田园春江、诗话江南等五大块，其意为五方和合，五瑞祥贵的平安社区。",
        images: [
          {
            path: "tonglu/yanyu-1.jpg",
          },
          {
            path: "tonglu/yanyu-2.jpg",
          },
        ]
      },
    ]
  },
  {
    city: "西安",
    assets: [
      {
        name: "同方服饰城",
        introduction: "“同方服饰城”项目位于西大街与大麦市街交汇处的东北角，东西沿街面总长137.88米，南北纵深34.74米，总建筑面积为1.8万平方米。其中，地上三层为大空间的商业营业厅；地下室其中间部分为营业大厅，东侧为停车库，项目前景良好。目前业态有酒吧、KTV、银行、酒店、歌友会、餐厅以及沿街专卖店。　　",
        images: [
          {
            path: "xian/fushi-1.jpg",
          },
          {
            path: "xian/fushi-2.jpg",
          },
          {
            path: "xian/fushi-3.jpg",
          },
          {
            path: "xian/fushi-4.jpg",
          },
          {
            path: "xian/fushi-5.jpg",
          },
        ]
      },
    ]
  },
  {
    city: "沈阳",
    assets: [
      {
        name: "三好街同方广场",
        introduction: "同方广场位于和平区中心地带，雄踞于东北IT商圈核心地带——三好街南端。三好街有“东北硅谷”之称，强大购买习惯养成，数码产品消费首选之地，数十万人潮涌动，人气鼎盛。项目北面紧邻百脑汇，南面是华强数码广场，西面一墙之隔是东北最盛名的东北大学，东侧就是目前最繁华的IT商业街三好街。距离五爱商圈，南塔商圈，五里河商圈，奥体商圈，中街商圈，太原街商圈驱车20分钟内均可到达。",
        images: [
          {
            path: "shenyang/sanhao-1.jpg",
          },
          {
            path: "shenyang/sanhao-2.jpg",
          },
          {
            path: "shenyang/sanhao-3.jpg",
          },
          {
            path: "shenyang/sanhao-4.jpg",
          },
        ]
      },
      {
        name: "同方时代广场",
        introduction: "沈阳·同方时代广场项目是同方联合控股集团2010年重点开发建设的复合型地产项目。项目集大型IT展售商场、高档写字楼、住宅、餐饮、娱乐于一体，各个功能板块将会形成良性研发、展示、销售的优势互动。同方广场，位于沈阳市和平区三好街西侧，南侧为城市规划路；北侧为百脑汇数码广场；西侧与东北大学相毗邻。地块呈东西狭长的不规则形，东西向长约316米，南北向长约144米。同方广场选址于和平区中心地带三好街南端。项目隶属于五里河商贸区，五里河是一个集商务、科技、文体、休闲、娱乐为一体的大型商贸区，它是高端、时尚、尊荣的象征。项目南临华强广场，东接三好街，北靠百脑汇数码广场，西侧就是具有百年盛名的东北大学。",
        images: [
          {
            path: "shenyang/shidai-1.jpg",
          },
          {
            path: "shenyang/shidai-2.jpg",
          },
          {
            path: "shenyang/shidai-3.jpg",
          },
          {
            path: "shenyang/shidai-4.jpg",
          },
        ]
      },
    ]
  },
 ]