<template>
  <div class="swiper-container">
    <div class="top-menu">
      <div class="top-menu-item" :class="{'top-menu-item-active': index == currentIndex}"
        v-for="(item, index) in cityDynamic.assets" :key="index" @click="click(index)">
        {{item.name}}
      </div>
    </div>
    <carousel
      :items-to-show="1"
      :autoplay="3000"
      :wrapAround="true"
      :transition="300"
      class="swiper">
      <slide v-for="(image, index) in cityDynamic.assets[currentIndex].images" :key="index">
        <div class="swiper-item">
          <img class="image" :src="require(`../assets/building/${image.path}`)" />
        </div>
      </slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
    <div class="text">
      {{cityDynamic.assets[currentIndex].introduction}}
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name: 'App',
  props: {
    city: {},
  },
  methods: {
    click(index) {
      this.currentIndex = index;
    }
  },
  watch: {
    city() {
      this.currentIndex = 0;
    },
  },
  computed: {
    cityDynamic() {
      return this.city;
    },
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
}
</script>

<style scoped>
.swiper-container {
  width: 100%;
}
.top-menu {
  width: 100%;
  margin-top: 5vw;
  display: flex;
  align-items: center;
}
.top-menu-item {
  font-size: 1.5vw;
  font-weight: 400;
  margin-left: 2vw;
  cursor: pointer;
  min-width: 12vw;
  box-sizing: border-box;
  padding: 0 1vw;
  height: 3.072vw;
  border-radius: 2vw 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(223, 223, 223, 1);
}
.top-menu-item-active {
  color: rgba(255, 255, 255, 1);
  background: rgba(208, 173, 115, 1);
}
.swiper-item {
  overflow: hidden;
  width: 75vw;
  height: 28.375vw;
  opacity: 1;
  border-radius: 10vw 0px 0px 0px;
  background: rgba(208, 173, 115, 1);
}
.swiper {
  margin-top: 5vw;
}
/*
.carousel__pagination {
  position: absolute;
  z-index: 10;
  margin-top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
*/
.text {
  margin-top: 2.9vw;
  font-size: 1.6vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  vertical-align: top;
}
.image {
  width: auto;
  height: 100%;
}
</style>
