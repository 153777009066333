<template>
  <div class="container">
    <img class="background-img" src="@/assets/main.jpg" />
    <div class="background-cover" />
    <div class="main">
      <div class="line" />
      <div class="title">
        同方联合控股集团有限公司
      </div>
      <div class="sub-title">
        同方联合控股集团有限公司成立于2000年，总部位于杭州市下城区凤起路334号同方财富大厦。公司主营实业投资，同时参与影视投资、高科技产业投资、酒店投资等，是一家多元化发展的投资型集团公司。
      </div>
      <div class="sub-title">
        同方联合下属多家具有一级、二级开发、建设资质的房地产开发公司和建设工程公司，有着20多年的历史。截至目前，集团公司房地产开发面积合计达到1000多万平方米。产品型态涵盖别墅、公寓住宅、专业市场、五星级酒店、商务写字楼等系列。同方联合精心打造的房产品牌遍布中国大江南北，从杭州、宁波、诸暨、桐庐、嘉兴到西安、天津、沈阳等大型省会城市，所开发的楼盘都是每个城市核心地段的精品楼盘。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.line {
  margin-top: 10vw;
  margin-left: 10vw;
  width: 15vw;
  border: 1px solid rgba(255, 255, 255, 1);
}
.title {
  margin-left: 10vw;
  margin-top: 2vw;
  font-size: 4vw;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.sub-title {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 2vw;
  font-size: 1.5vw;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.container {
  position: relative;
  width: 100vw;
  height: 52.66vw;;
}
.background-img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 52.66vw;;
  z-index: 1;
}
.background-cover {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 52.66vw;;
  background: rgba(0, 0, 0, 0.55);
  z-index: 2;
}
.main {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 52.66vw;;
  z-index: 3;
}
</style>
