<template>
  <div class="container">
    <img class="logo" src="@/assets/logo.png">
    <div class="tab">
      公司简介
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.container {
  margin-top: 2vw;
  display: flex;
  align-items: flex-start;
  height: 3.9vw;
  margin-bottom: 2vw;
}
.logo {
  margin-left: 20px;
  width: 15vw;
  height: 4.6vw;
}
.tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 1;
  font-size: 1.5vw;
  font-weight: 400;
  color: rgba(179, 119, 30, 1);
  margin-left: 6vw;
}
</style>
