<template>
  <div class="container">
    <img class="background" src="@/assets/asset-background.png"/>
    <div class="left-menu">
      <div class="top">
        <img class="logo" src="@/assets/menu-top.svg">
      </div>
      <div class="menu">
        <div class="city" :class="{'active': currentIndex == index}"
          v-for="(item, index) in cities" :key="index" @click.stop="click(index)">
          {{item.city}}
        </div>
      </div>
    </div>
    <div class="right">
      <CitySwiper :city="cities[currentIndex]" />
    </div>
  </div>
</template>

<script>
import CitySwiper from '../components/CitySwiper.vue'
import { data } from '../data/data.js'


export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      cities: data,
      currentIndex: 0,
    }
  },
  components: {
    CitySwiper,
  },
  methods: {
    click(index) {
      this.currentIndex = index;
    }
  }
}
</script>

<style scoped>
.menu {
  width: 100%;
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  position: relative;
  width: 100vw;
  min-height: 60.99vw;
  padding-bottom: 2vw;
  display: flex;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.left-menu {
  width: 25%
}
.right {
  width: 75%
}
.city {
  font-size: 1.5vw;
  font-weight: 400;
  cursor: pointer;
  margin-top: 1vw;
  box-sizing: border-box;
  border-bottom: 4px solid rgba(179, 119, 30, 0);
}
.active {
  border-bottom: 4px solid rgba(179, 119, 30, 1);
}
.logo {
  margin-left: 5vw;
  margin-top: 5vw;
  width: 15vw;
  height: 17.83vw;
}
</style>
