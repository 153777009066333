<template>
  <Header />
  <Introduction />
  <Asset />
  <Contact />
</template>

<script>
import Header from './components/Header.vue'
import Introduction from './components/Introduction.vue'
import Asset from './components/Asset.vue'
import Contact from './components/Contact.vue'


export default {
  name: 'App',
  components: {
    Header,
    Introduction,
    Asset,
    Contact,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
</style>
